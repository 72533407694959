import { PortalServico } from '@/utils/';

/**
 * @type {QuestionariosERespostas}
 */
export default class QuestionariosERespostas extends PortalServico {

    listarProgramasCPGsEAutoavaliacoes(params) {
        return this.post('/uspproc/listar/janus/uspprocListarAutoavaliacoesPorPessoaEAno', params);
    }
    listarAnosDeAvaliacao() {
        return new Promise ((resolve, reject) =>  {resolve([{ano: 2024}])});
    }
}