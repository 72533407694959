<template>
  <div>
    <ul class="list-unstyled">
      <div class="m-3" v-if="itens != null && itens.length === 0">Não há questionários respondidos para Programa, ano e tipo de avaliador.</div>
      <li v-for="questionarioRespondido in itens">
        <div>
          <ItemQuestionarioRespondido
            :tituloDoCartao="obtemTituloDoCartao(questionarioRespondido)"
            :questoesERespostas="fitraLinhasDeUmQuestionario(questionarioRespondido.linhasDeUmQuestionario)"/>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ItemQuestionarioRespondido from "./ItemQuestionarioRespondido.vue"

export default {
  name: "ListaQuestionariosRespondidos",

  components: {
    ItemQuestionarioRespondido,
  },

  props: {
    itens: {
      type: Array
    },
  },

  methods:{
    obtemTituloDoCartao(questionarioRespondido){
      /*a linha a seguir parte do princípio que as linhasDeUmQuestionario estão ordenadas de tal maneira que a questão número 1 esteja na posição
      0 do array, a questão número 2, esteja na posição 1, e assim por diante. Essa ordenação está sendo feita no método populaAbas() em
      ConsultaQuestionarios.vue.
      Para isso funcionar também partimos do princípio que a pergunta que pergunta o nome da pessoa que responde a auto-avaliação é a cujo
      código da questão é 2. Se isso for alterado, será necessário consertar esta parte do código.*/
      return questionarioRespondido.linhasDeUmQuestionario[1].textoDaQuestao + ": " + questionarioRespondido.linhasDeUmQuestionario[1].textoDaResposta;
    },
    fitraLinhasDeUmQuestionario(linhasDeUmQuestionario){
      return linhasDeUmQuestionario.filter((linha) =>{
        //não queremos exibir a questão do nome da pessoa que respondeu o questionário, isso já está no título do cartão
        return linha.codigoDaQuestao != "2" 
        //os pareceristas tem uma questão se pergunta se querem receber atestado pelo parecer; não queremos exibir isso
        && !(linha.nomeDoQuestionario === "Janus:Formulário de Autoavaliação - Pareceristas" && linha.codigoDaQuestao === "5" )
      });
    },
   
  }
};
</script>