<template>
  <div class="acoes">
    <button
      v-if="tarefa.podeSeguir()"
      type="button"
      class="btn btn-default btn-sm"
      aria-label="Visualizar tarefa"
      @click="seguirLinkTarefa(tarefa)"
    >
      <i class="fas fa-external-link-alt" />
    </button>
    <button 
      v-if="tarefa.podeAdiar()"
      type="button"
      class="btn btn-default btn-sm"
      aria-label="Adiar tarefa"
      @click="tarefaAdiar(tarefa)"
    >
      <i class="fas fa-stopwatch" />
    </button>
    <button 
      v-if="tarefa.podeResolver()"
      type="button"
      class="btn btn-default btn-sm"
      aria-label="Concluir tarefa"
      @click="baixarTarefa(tarefa)"
    >
      <i class="fas fa-check" />
    </button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { Actions, Mutations } from '../../../store/types';
import Tarefa from '@/portal/dominio/notificacoes/Tarefa';
export default {

    name: 'PortalTarefasAcoes',

    props: {
        tarefa: {
            required: true,
            type: Tarefa
        }
    },

    data(){
        return {
            msg: {
                erro: {
                    titulo: 'Erro ao baixar tarefa!',
                },
                sucesso: {
                    titulo: 'Baixa de tarefa',
                    mensagem: 'Tarefa marcada como concluída com sucesso!'
                }
            }
        };
    },

    methods: {
        ...mapActions({
            baixar: Actions.NOTIFICACOES.BAIXAR_TAREFA,
        }),
        ...mapMutations({
           tarefaAdiar: Mutations.NOTIFICACOES.TAREFA_PARA_ADIAR 
        }),
        baixarTarefa(tarefa){
            const self = this;
            self.baixar(tarefa)
                .then(() => self.$toast.success(`${this.msg.sucesso.titulo}! ${this.msg.sucesso.mensagem}`))
                .catch(erro => {
                    self.$toast.error(`${this.msg.erro.titulo}! ${erro.mensagem}`);
                    this.$logger.error('Erro ao baixar tarefa: '+erro.mensagemDesenvolvedor);
                });
        },
        seguirLinkTarefa(tarefa) {
            let url = tarefa.url;
            if(url.indexOf('http') !== -1){
                window.open(url, '_blank');
            } else {
                if(url[0] === '/'){
                    url = url.substring(1);
                }
                this.$router.push(`/servicos/${tarefa.codigoArea}/${url}`);
            }
        }
    }

}
</script>
