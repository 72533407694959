// Nossas Configurações
import axios from 'axios';
import Vue from 'vue';
import App from './App.vue';

// Estruturas do projeto
import router from '@/router';
import store from "@/store";

// Terceiros
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueGtag from 'vue-gtag';
import VuePortal from '@linusborg/vue-simple-portal';
import VCalendar from 'v-calendar';
import Toast from "vue-toastification";
import CKEditor from 'ckeditor4-vue';
import { vMaska } from "maska"

// Plugins Próprios
import UspVue, { UspPrinter } from '@/componentes/main.js';
import { PortalLogger } from '@/utils';
import constPlugin from '@/usp-assina/plugins/constantes';
import { StorageKeys } from './portal/constantes';
import { clicarFora } from '@/portal/diretivas'; 
// FontAwesome
import '@fortawesome/fontawesome-free/css/all.css'
import "vue-toastification/dist/index.css";


axios.defaults.withCredentials = true;
axios.interceptors.response.use(resp => {
  return resp;
}, (error) => {
  if (error?.response?.data.status === 401) {
    const path = window.location.pathname
    if(path !== '/'){
      console.log(`Guardando rota ${path}`);
      localStorage.setItem(StorageKeys.AUTH.REDIRECT, path);
      router.push({ name: 'home'});
    }
    if(store.getters['auth/autenticado']){
      store.dispatch('auth/deslogar');
    }
  }
  return Promise.reject(error);
});

Vue.config.productionTip = true;
Vue.config.devtools = true;
Vue.config.performance = true;

Vue.use(UspVue);
Vue.use(Toast, {});
Vue.use(UspPrinter)
Vue.directive('clicar-fora', clicarFora);
Vue.directive("maska", vMaska)
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

Vue.use(VuePortal, {
  name: 'portal', // optional, use to rename component
});

Vue.use(VueReCaptcha, {
  siteKey: '6LfsVq4UAAAAALGvYCDllwImhuycVqirjthOnu4S'
});

Vue.use(constPlugin);

Vue.use(VueGtag, {
  config: { 
    id: process.env.VUE_APP_GA
  }
}, router)

Vue.use(PortalLogger, {

});

Vue.use( CKEditor );

Vue.prototype.$baseUrl = process.env.VUE_APP_LEGADO_URL;

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
