class UspPrinter {

  constructor(){
    this.setOptions();
  }

  _abrirJanelaImpressao(url, titulo, opcoes){
    let janela = null;
    janela = window.open(url, titulo, opcoes);
    if (!janela.opener) {
      janela.opener = this;
    }
    janela.focus();
    return janela;
  }

  _escreverNaJanela(janela, titulo, conteudo){

    // Pega os estilos existentes na Window atual
    const estilosAtuais = this._obterEstilosAtuais()

    janela.document.documentElement.innerHTML = `
      <html>
        <head>
          <title>${titulo}</title>
          ${estilosAtuais}
        </head>
        <body>
          ${conteudo}
        </body>
      </html>
    `
  }

  _obterEstilosAtuais() {
    let css = ''
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
      css += node.outerHTML
    }
    return css
  }

  imprimirDiv(titulo, conteudoDiv, retorno = () => true) {
    const url = '';
    // Cria o elemento de janela no JS
    const janela = this._abrirJanelaImpressao(url, titulo, this.openOpts);
    // Escreve na nova janela conteúdo
    this._escreverNaJanela(janela, titulo, conteudoDiv)
    // Seta o timeout de 1000ms para esperar tudo carregar
    setTimeout(() => {
      janela.document.close(); // Fecha a escrita do Documento
      janela.focus(); // Traz a janela para frente
      janela.print(); // Manda imprimir a janela
      setTimeout(function () {window.close();}, 1); // Espera um pouto para fechar a window
      retorno()
    }, 1000);
      
    return true;
  }

  setOptions(opt={}){
    this.openOpts = (opt.openOpts || {});
  }

  install(vue, opts){   
    this.setOptions(opts);
    vue.prototype.$impressora = this;
  }
  
}

export default new UspPrinter();
