import ConsultaQuestionarios from '../views/ConsultaQuestionarios.vue'

export const routes = [
  {
    path: '/consulta-questionarios/',
    name: 'consulta-questionarios',
    component: ConsultaQuestionarios,
    meta: {
      breadcrumb:[
        { nome: 'Consulta Questionários' }
      ]
    }
  }
];