import { render, staticRenderFns } from "./uspTabela.vue?vue&type=template&id=0f97eb01&"
import script from "./uspTabela.vue?vue&type=script&lang=js&"
export * from "./uspTabela.vue?vue&type=script&lang=js&"
import style0 from "./uspTabela.vue?vue&type=style&index=0&id=0f97eb01&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports