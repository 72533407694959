<template>
  <uspCartao :titulo="tituloDoCartao" :key="tituloDoCartao">
    <template slot="corpo">
      <ul class="list-unstyled">
        <li v-for="questaoEResposta in questoesERespostas" >
          <p class="font-weight-bold">{{ questaoEResposta.textoDaQuestao }}</p>
          <pre>{{questaoEResposta.textoDaResposta}}</pre>
        </li>
      </ul>
    </template>
  </uspCartao>
</template>

<script>
export default {
  name: 'ItemQuestionarioRespondido',

  mounted(){
  },

  methods:{
  },

  props: {
    tituloDoCartao: null,
    questoesERespostas: {
      type: Array,
      required: true,
    },
  },

  data(){
    return {
    }
  }
};
</script>
<style>
</style>
