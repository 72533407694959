const LicitacoesDetalhes = () => import(/* webpackChunkName: "chunk-contratacoes" */ '../views/LicitacoesDetalhes')
const LicitacoesBusca = () => import(/* webpackChunkName: "chunk-contratacoes" */ '../views/LicitacoesBusca')
const LicitacoesBem = () => import(/* webpackChunkName: "chunk-contratacoes" */ '../views/LicitacoesBem')

export const routes = [
  {
    path: '/',
    name: 'Busca',
    component: LicitacoesBusca,
    meta: {
      breadcrumb:[
        { nome: 'Licitações/Contratações' }
      ]
    }
  },
  {
    path: '/:codpcddsp',
    name: 'Detalhamento',
    component: LicitacoesDetalhes,
    meta: {
      breadcrumb:[
        { nome: 'Licitações/Contratações', rawlink: '/contratacoes/' },
        { nome: 'Contratação #${codpcddsp}', link: '/contratacoes/${codpcddsp}' },
      ]
    },
    beforeEnter(to, from, next){
      let { query } = from;
      let item = to.meta.breadcrumb[0];
      let querystring = Object.entries(query).map(([k,v]) => `${k}=${v}`).join('&');
      item.link = `${item.rawlink}?${querystring}`;
      next();
    }
  },
  {
    path: '/bem/:codbem',
    name: 'ItemBem',
    component: LicitacoesBem,
    meta: {
      breadcrumb:[
        { nome: 'Licitações/Contratações', rawlink: '/contratacoes/' },
        { nome: 'Bem nº ${codbem}', link: '/contratacoes/bem/${codbem}' },
      ]
    },
    beforeEnter(to, from, next){
      let itemFrom = Object.keys(from.meta).length === 0 ? { nome: 'Licitações/Contratações', link: '/contratacoes/' } : from.meta.breadcrumb[0];
      let item = to.meta.breadcrumb[0];
      let linkFrom = itemFrom.link ;
      item.link = linkFrom;
      next();
    }
  }
]