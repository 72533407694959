import { NOTIFICACOES_CARREGANDO, NOTIFICACOES_CARREGADO } from '../../constantes';
import { Actions, Getters, Mutations } from '../types';
import { PortalLogger as log } from '@/utils';

import NotificacoesServico from '@/portal/dominio/notificacoes/NotificacoesServico';
import Vue from 'vue';

const api = NotificacoesServico.build({})

// Estado inicial
const state = {
    aviso: {},
    avisos: [],
    novidades: [],
    status: NOTIFICACOES_CARREGANDO,
    status_aviso: NOTIFICACOES_CARREGANDO,
    tarefaParaAdiar: null,
    tarefas: [],
}

// getters
const getters = {
    [Getters.NOTIFICACOES.AVISO_PARA_VISUALIZAR]: (st) => st.aviso,
    [Getters.NOTIFICACOES.AVISOS]: (st) => st.avisos,
    [Getters.NOTIFICACOES.NOVIDADES]: (st) => st.novidades,
    [Getters.NOTIFICACOES.STATUS_CARREGADO]: (st) => st.status === NOTIFICACOES_CARREGADO,
    [Getters.NOTIFICACOES.STATUS_AVISO_CARREGADO]: (st) => st.status_aviso === NOTIFICACOES_CARREGADO,
    [Getters.NOTIFICACOES.TAREFA_PARA_ADIAR]: (st) => st.tarefaParaAdiar,
    [Getters.NOTIFICACOES.TAREFAS_ADIADAS]: (st) => st.tarefas.filter(t => t.adiado),
    [Getters.NOTIFICACOES.TAREFAS_PENDENTES]: (st) => st.tarefas.filter(t => !t.adiado)
}

// actions
const actions = {
    [Actions.NOTIFICACOES.ADIAR_TAREFA]: ({ commit }, { tarefa, novaData }) => {
        return api.adiarTarefa(tarefa.codigo, tarefa.codigoArea, novaData)
                .then((retorno) => commit(Mutations.NOTIFICACOES.TAREFA_DATA_ADIADA, { tarefa, novaData, retorno }));
    },
    [Actions.NOTIFICACOES.BAIXAR_TAREFA]: ({ commit }, tarefa) => {
        return api.baixarTarefa(tarefa.codigo, tarefa.codigoArea)
                .then(() => commit(Mutations.NOTIFICACOES.TAREFA_RETIRAR, tarefa));
    },
    [Actions.NOTIFICACOES.CARREGAR]: ({ commit }) => {
        commit(Mutations.NOTIFICACOES.STATUS, NOTIFICACOES_CARREGANDO);
        log.info('Carregando notificações do usuário');
        return api.obterNotificacoes()
           .then(notificacoes => {
              commit(Mutations.NOTIFICACOES.TAREFAS, notificacoes.tarefas);
              commit(Mutations.NOTIFICACOES.AVISOS, notificacoes.avisos);
              commit(Mutations.NOTIFICACOES.NOVIDADES, notificacoes.novidades);
           }).finally(() => {
              commit(Mutations.NOTIFICACOES.STATUS, NOTIFICACOES_CARREGADO);
           });
    },
    [Actions.NOTIFICACOES.OBTER_AVISO]: async ({ commit }, aviso) => {
        commit(Mutations.NOTIFICACOES.STATUS_AVISO, NOTIFICACOES_CARREGANDO);
        commit(Mutations.NOTIFICACOES.AVISO_PARA_VISUALIZAR, aviso);
        return api.obterAvisoweb(aviso)
            .then(avisoweb => {
                commit(Mutations.NOTIFICACOES.AVISO_PARA_VISUALIZAR, avisoweb);
                return avisoweb;
            }).catch((erro) => {
                if(erro.mensagemDesenvolvedor) log.error(erro.mensagemDesenvolvedor);
                Vue.$toast.error(`Erro ao visualizar Aviso: ${erro.mensagem}`);
            }).finally(() => {
                commit(Mutations.NOTIFICACOES.STATUS_AVISO, NOTIFICACOES_CARREGADO);
            });
    },
    [Actions.NOTIFICACOES.VISUALIZAR_TAREFA]: ({ commit }, tarefa) => {
        commit(Mutations.NOTIFICACOES.ESTENDIDO, tarefa)
        if(tarefa.estendido){
            return api.visualizarTarefa(tarefa.codigo, tarefa.codigoArea)
                    .then((resultado) => {
                    commit(Mutations.NOTIFICACOES.TAREFA_VISUALIZACAO, {
                        tarefa,
                        data: resultado
                    });
                })
        }
    }
}

// mutations
const mutations = {
    [Mutations.NOTIFICACOES.AVISO_PARA_VISUALIZAR]: (st, aviso) => {
        if(aviso){
            let aw = st.avisos.find(a => a.codigo === aviso.codigo);
            if(aw){
                aw.visualizacao = aviso.visualizacao;
            }
            let novidade = st.novidades.find(n => n.tipo === 'aviso' && n.codigo === aviso.codigo);
            if(novidade){
                novidade.visualizacao = aviso.visualizacao;
            }
        }
        st.aviso = aviso || {};
    },
    [Mutations.NOTIFICACOES.AVISOS]: (st, avisos) => st.avisos = avisos,
    [Mutations.NOTIFICACOES.ESTENDIDO]: (st, alerta) => alerta.estendido = !alerta.estendido,
    [Mutations.NOTIFICACOES.NOVIDADES]: (st, novidades) => st.novidades = novidades,
    [Mutations.NOTIFICACOES.STATUS]: (st, status) => st.status = status,
    [Mutations.NOTIFICACOES.STATUS_AVISO]: (st, status) => st.status_aviso = status,
    [Mutations.NOTIFICACOES.TAREFA_DATA_ADIADA]: (st, dados) => {
        let tarefa = dados.tarefa;
        tarefa.adiadoPara = dados.novaData;
        tarefa.adiado = dados.retorno.adiado === 'S';
    },
    [Mutations.NOTIFICACOES.TAREFA_PARA_ADIAR]: (st, tarefa) => st.tarefaParaAdiar = tarefa,
    [Mutations.NOTIFICACOES.TAREFA_RETIRAR]: (st, tarefa) => {
        st.tarefas = st.tarefas.filter(t => t.codigo !== tarefa.codigo);
    },
    
    [Mutations.NOTIFICACOES.TAREFA_VISUALIZACAO]: (st, { tarefa, data }) => {
        tarefa.visualizadoEm = data.dtavlzalepoa;
        let novidade = st.novidades.find(n => n.tipo === 'alerta' && n.codigo === tarefa.codigo);
        if(novidade){
            novidade.visualizacao = data.dtavlzalepoa;
        }
    },
    [Mutations.NOTIFICACOES.TAREFAS]: (st, tarefas) => st.tarefas = tarefas
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}