<template>
  <section>
    <div class="container-fluid">
      <uspCartao titulo="Autoavaliação" :fechavel="false">
        <template slot="corpo">
          <uspAlerta 
          v-show="!!erro"
          variante="perigo"
          fechavel
          >
          {{ erro }}
        </uspAlerta>
          <!-- Filtros -->
          <div class="filtros mb-3">
            <form class="d-flex flex-wrap justify-content-start">
              <div class="mr-3">
                <label>
                  Ano de Autoavaliação
                  <select v-model="anoSelecionado" class="form-control form-control-sm" @change="obtemCpgsProgramasEAutoavaliacoesParaAno">
                    <option v-for="(value, key) in anosDisponiveis" :key="key" :value="value">
                      {{ value }}
                    </option>
                  </select>
                </label>
              </div>
              <div class=" mr-3">
                <label>
                  CPG <span v-show="!carregado" class="spinner-border spinner-border-sm"></span >
                  <select v-model="cpgSelecionada" class="form-control form-control-sm cpgSelect" @change="filtraProgramasEPopulaAbas" >
                    <option v-for="(value, key) in cpgsDisponiveis" :key="key" :value="value">
                      {{ value.nomeAbreviadoDoColegiado + " (" + value.codigoDoColegiado + ")" }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="mr-3">
                <label>
                  Programa <span v-show="!carregado" class="spinner-border spinner-border-sm"></span >
                  <div v-if="!carregado" class="usp-loading-item" />
                  <select v-model="programaSelecionado" class="form-control form-control-sm programaSelect" @change="populaAbas">
                    <option v-for="(value, key) in programasDisponiveisParaCpgSelecionada" :key="key" :value="value">
                      {{ value.nomeDoPrograma + " (" + value.codigoDoPrograma + ")" }}
                    </option>
                  </select>
                </label>
              </div>
            </form>
          </div>

          <uspAbas :itens="dadosParaAbas" />

        </template>
      </uspCartao>
    </div>
  </section>
</template>

<script>
import QuestionariosERespostas from "../classes/QuestionariosERespostas";
import ListaQuestionariosRespondidos from "../componentes/ListaQuestionariosRespondidos.vue";

const api = QuestionariosERespostas.build({});

export default {
  name: 'ConsultaQuestionarios',

  mounted() {
    const self = this;
    self.populaFiltros();
  },
  methods: {
    /*Ao iniciar a view, carregamos os anos. Se um nao estiver selecionado, já carregamos todas as autoavaliações para esque usuário e ano*/
    populaFiltros() {
      const self = this;
      api.listarAnosDeAvaliacao().then((linhasDosAnosDisponiveis) => {
        self.preencheAnosDeAvaliacaoDisponiveis(linhasDosAnosDisponiveis);
        if(self.anoSelecionado != null){
          self.obtemCpgsProgramasEAutoavaliacoesParaAno(self.anoSelecionado);
        }
      });
    },

    /* obtemos todas as autoavaliações para o usuário do ano selecionado. Os filtros para determinar como popular as abas são feitos
    todos  no próprio Vue. Fazemos assim para exibir para o usuário apenas Programas que tenham avaliações.*/
    obtemCpgsProgramasEAutoavaliacoesParaAno(anoSelecionado){
      const self = this;
      self.carregado = false;
      api.listarProgramasCPGsEAutoavaliacoes({anoDaAutoavaliacao: anoSelecionado}).then(( linhasCpgsProgramasEAutoavaliacoes ) =>{
        self.autoavaliacoesDisponiveis = linhasCpgsProgramasEAutoavaliacoes;
        self.preencheCpgsEProgramasDisponiveis(linhasCpgsProgramasEAutoavaliacoes);
        if(self.cpgSelecionada != null){
          self.filtraProgramasPorCPGSelecionada();
        }
        self.carregado = true;
      }).catch((erro) => {
        self.cpgsDisponiveis = [];
        self.programasDisponiveis = [];
        self.programasDisponiveisParaCpgSelecionada = [];
        self.carregado = true;
        self.erro = ((erro || "").mensagem || erro);
      });
    },

    /*ao selecionar uma CPG, filtra os programas e, na hipótese de haver apenas um programa na CPG, popula as abas com as autoavaliações*/
    filtraProgramasEPopulaAbas() {
      this.filtraProgramasPorCPGSelecionada();
      this.populaAbas();
    },

    /*Lê todas as linhas de autoavaliação do ano selecionado disponíveis para o usuário e preenche os 
    dropdowns com as CPGs e Programas destas linhas. Dessa maneira, não apareceram programas ou CPGs
    que o usuário tenha acesso, mas não tenham nenhuma autoavaliação naquele ano*/
    
    
    /* Explicação: No "data()" dessa view há 2 arrays de atenção:
      programasDisponiveis, que contém todos os programas detodas as CPGs que o usuário tem acesso e
      programasDisponiveisParaCpgSelecionada, que contém apenas os programas disponiveis para a CPG selecionada */
    preencheCpgsEProgramasDisponiveis(linhasCpgsProgramasEAutoavaliacoes) {
      const self = this;
      const codigoDasCpgs = new Set(); //usado apenas para controle de fluxo, para evitar de adicionar a mesma CPG mais de uma vez
      const codigoDosProgramas = new Set(); //usado apenas para controle de fluxo, para evitar de adicionar a mesma CPG mais de uma vez
      linhasCpgsProgramasEAutoavaliacoes.forEach((linha) => {
        if(!codigoDosProgramas.has(linha.codigoDoPrograma)){
          codigoDosProgramas.add(linha.codigoDoPrograma);
          self.programasDisponiveis.push({ codigoDoPrograma: linha.codigoDoPrograma, nomeDoPrograma: linha.nomeDoPrograma, codigoDoColegiado: linha.codigoDoColegiado });
        }
        
        if (!codigoDasCpgs.has(linha.codigoDoColegiado)) {
          codigoDasCpgs.add(linha.codigoDoColegiado);
          self.cpgsDisponiveis.push({ codigoDoColegiado: linha.codigoDoColegiado, nomeAbreviadoDoColegiado: linha.nomeAbreviadoDoColegiado })
        }

      })
      if(self.cpgsDisponiveis.length === 1){
        self.cpgSelecionada = self.cpgsDisponiveis[0];
      }
      self.cpgsDisponiveis.sort(function(a, b) {
          var textA = a.nomeAbreviadoDoColegiado.toUpperCase();
          var textB = b.nomeAbreviadoDoColegiado.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },

    preencheAnosDeAvaliacaoDisponiveis(linhasDosAnosDisponiveis) {
      const self = this;
      linhasDosAnosDisponiveis.forEach((linha) => {
        self.anosDisponiveis.push(linha.ano);
      })
      if(self.anosDisponiveis.length === 1){
        self.anoSelecionado = self.anosDisponiveis[0];
      }
    },

    populaAbas() {
      const self = this;
      if(self.programaSelecionado === null || self.anoSelecionado === null){
        return;
      }

      const questionariosDaCoordenacao = [];
      const questionariosDoRelator = [];
      const questionariosDosPareceristas = [];

      /* itera entre as linhas do resultado da proc e faz a triagem de cada tipo de questionário previsto*/      
      self.autoavaliacoesDisponiveis.filter((linha) =>{
        return linha.codigoDoPrograma === self.programaSelecionado.codigoDoPrograma;
      })
     .forEach((linha) => {
        if (linha.nomeDoQuestionario === "Janus:Formulário de Autoavaliação - Coordenação") {
          questionariosDaCoordenacao.push(linha);
        } else if (linha.nomeDoQuestionario === "Janus:Formulário de Autoavaliação - Relatores") {
          questionariosDoRelator.push(linha);
        } else if (linha.nomeDoQuestionario === "Janus:Formulário de Autoavaliação - Pareceristas") {
          questionariosDosPareceristas.push(linha);
        }
      });

      self.prencheItensDasAbas(
        questionariosDaCoordenacao.sort(self.ordernaPorCodigoDaQuestao),
        questionariosDoRelator.sort(self.ordernaPorCodigoDaQuestao),
        questionariosDosPareceristas.sort(self.ordernaPorCodigoDaQuestao));
    },

    ordernaPorCodigoDaQuestao(a, b){
      return (a.codigoDaQuestao < b.codigoDaQuestao) ? -1 : (a.codigoDaQuestao > b.codigoDaQuestao) ? 1 : 0;
    },

    prencheItensDasAbas(questionariosDaCoordenacao, questionariosDoRelator, questionariosDosPareceristas) {
      const self = this;

      self.preencheItensDaAba(self.INDICE_DAS_ABAS["Coordenação"], questionariosDaCoordenacao);
      self.preencheItensDaAba(self.INDICE_DAS_ABAS["Pareceristas"], questionariosDosPareceristas);
      self.preencheItensDaAba(self.INDICE_DAS_ABAS["Relator"], questionariosDoRelator);
    },
    /*Este método constroi os questionarios respondidos como um objeto no formato
    {
      codigoDoQuestionario int,
      nomeDoQuestionario string,
      sequencialRespostaDoQuestionario int,
      linhasDeUmQuestionario Array
    }
      que é um formato mais fácil de usar para popular os elementos da tela corretamente do que as
      linhas do resultado da proc. As perguntas e respostas são agrupadas por número sequencial de resposta*/
    preencheItensDaAba(indiceDaAba, linhasDaAba) {
      const self = this;
      const sequenciaisResposta = new Set();
      linhasDaAba.forEach((linha) => {
        sequenciaisResposta.add(linha.sequencialResposta);
      })

      const questionariosRespondidos = [];
      sequenciaisResposta.forEach(numseqrpa => {
        const linhasDeUmQuestionario = linhasDaAba.filter(linha => linha.sequencialResposta === numseqrpa);
        questionariosRespondidos.push({
          codigoDoQuestionario: linhasDaAba[0].codigoDoQuestionario,
          nomeDoQuestionario: linhasDaAba[0].nomeDoQuestionario,
          sequencialRespostaDoQuestionario: numseqrpa,
          linhasDeUmQuestionario: linhasDeUmQuestionario
        })
      })
      // por que usamos o componente uspAbas, precisamos centralizar todos os dados que irão popular as abas
      // neste array dadosParaAbas
      self.dadosParaAbas[indiceDaAba].itensDoComponente = questionariosRespondidos;
    },
    filtraProgramasPorCPGSelecionada() {
      const self = this;
      self.programasDisponiveisParaCpgSelecionada = [];
      self.programasDisponiveis.filter((programa) => {
        return programa.codigoDoColegiado === self.cpgSelecionada.codigoDoColegiado;
      }).forEach((linha) => {
        self.programasDisponiveisParaCpgSelecionada.push({ codigoDoPrograma: linha.codigoDoPrograma, nomeDoPrograma: linha.nomeDoPrograma });
      });

      if(self.programasDisponiveisParaCpgSelecionada.length === 1){
        self.programaSelecionado = self.programasDisponiveisParaCpgSelecionada[0];
        this.populaAbas();
      }

      self.programasDisponiveisParaCpgSelecionada.sort(function(a, b) {
          var textA = a.nomeDoPrograma.toUpperCase();
          var textB = b.nomeDoPrograma.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
  },

  data() {
    return {
      erro: "",
      carregado: true,
      INDICE_DAS_ABAS: {"Coordenação": 0,  "Pareceristas": 1, "Relator": 2} ,

      cpgsDisponiveis: [],
      programasDisponiveis: [],
      programasDisponiveisParaCpgSelecionada: [],
      anosDisponiveis: [],

      autoavaliacoesDisponiveis: [],

      cpgSelecionada: null,
      programaSelecionado: null,
      anoSelecionado: null,

      dadosParaAbas: [
        {
          titulo: "Coordenação",
          componente: ListaQuestionariosRespondidos,
          itensDoComponente: null
        },
        {
          titulo: "Pareceristas",
          componente: ListaQuestionariosRespondidos,
          itensDoComponente: null
        },
        {
          titulo: "Relator",
          componente: ListaQuestionariosRespondidos,
          itensDoComponente: null
        },
      ],
    }
  }

}
</script>

<style>
pre {
  white-space: -moz-pre-wrap;
  /* Mozilla, supported since 1999 */
  white-space: -pre-wrap;
  /* Opera */
  white-space: -o-pre-wrap;
  /* Opera */
  white-space: pre-wrap;
  /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word;
  /* IE 5.5+ */
}


.cpgSelect, .programaSelect{
  min-width: 20vw; /* evita que o redimensionamento do dropdown seja muito disruptivo */
}
</style>