<template>
  <div 
    :class="{
      'table-container': true,
      'table-responsive': responsivo
    }"
  >
    <table
      :class="{
        'table': true
      }"
      :aria-label="titulo"
    >
      <thead>
        <tr>
          <th
            v-for="(campo, idx3) in campos"
            :key="`${id}-${campo.campo}`"
            scope="col"
          >
            <div
              :class="[{
                'conteudo': true,
                'ordenavel': campo.ordenavel},
                alinhamentos[idx3]
              ]"
              @click="ordenar(campo)"
            >
              {{ campo.nome }}
              <em 
                v-if="campo.ordenavel"
                :class="obterIconeOrdenavel(campo)"
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(dado, idx) in dadosPaginados"
          :key="`${id}-row-${idx}`"
          :class="{
            'acionavel': clicavel,
            'selecionada': selecionada === idx
          }"
          @click="handleClick(dado, idx)"
          @dblclick="handleDblClick(dado, idx)"
        >
          <td 
            v-for="(campo, idx2) in campos"
            :key="`${id}-row-${idx}-col-${idx2}`"
            :class="alinhamentos[idx2]"
          >
            <slot 
              v-if="campo.slot"
              :name="campo.slot"
              :data="dado"
            />
            <span v-else-if="campo.campo !== 'acoes'">
              {{ 
                campo.formato && typeof campo.formato === 'function' ?
                  campo.formato(dado[campo.campo]) :
                  dado[campo.campo] 
              }}
            </span>
            <slot 
              v-else
              name="acoes"
              :data="dado"
            >
              <span
                v-for="acao in acoes"
                :key="acao.id"
                class="mr-1"
              >
                <uspBotao
                  class="text-nowrap mb-1"
                  :texto="acao.titulo"
                  :icone="acao.icone"
                  :variante="acao.variante"
                  :tamanho="acao.tamanho"
                  :desabilitado="!tratarCondicao(acao.condicao, dado)"
                  @clicar="selecionar(acao.titulo, dado)"
                />
              </span>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="paginacao"
      class="mt-4"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <uspPaginacao
            icones
            tamanho="pq"
            :paginas="paginas"
            @paginar="paginar"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UspIdUnico from "@/componentes/plugins/UspIdUnico";
import uspBotao from "../uspBotao/uspBotao.vue";
import { TABELA_ALINHAMENTO } from "./constantes";
import { formataStringAcentuada } from "@/utils/Formatador";

export default {
  name: 'UspTabela',
  components: { uspBotao },
  props: {
    /**
     * Vetor de dados a serem mostrados na tabela
     */
    dados: {
      type: Array,
      required: true,
    },
    /**
     * Vetor de definições dos campos a serem renderizados na tabela
     */
    campos: {
      type: Array,
      required: true,
    },
    acoes: {
      type: Array,
      default: () => []
    },
    acoesDropdown: {
      type: Boolean,
      default: false,
      required: false,
    },
    paginacao: {
      type: Boolean,
      default: false,
      required: false,
    },
    porPagina: {
      type: Number,
      default: 10,
      required: false,
    },
    /**
     * Marca se devemos colocar o container da tabela como responsivo (scroll lateral)
     */
    responsivo: {
      type: Boolean,
      default: true
    },
    /**
     * Título para ser adicionado ao label da tabela, por questões de acessibilidade
     */
    titulo:{
      type: String,
      default: 'Tabela Dinâmica'
    },
    alinhamento: {
      type: String,
      default: "esquerda",
      validator: (value) => {
        return (
          TABELA_ALINHAMENTO.map(function(e) {
            return e.pt;
          }).indexOf(value) !== -1
        );
      },
    },
    /**
     * Marca se a linha da tabela pode ser 'clicada' (clique simples ou duplo)
     */
    clicavel: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      ordemDir: '',
      ordemProp: '',
      numericoProp: '',
      id: '',
      paginaAtual: 1,
      selecionada: false
    };
  },

  computed: {
    dadosOrdenados(){
      const self = this;
      return self.ordemProp ?
        self.dados.slice().sort((a, b) => {
          const valueA = !a[self.ordemProp] ? null : self.numericoProp ? parseFloat(a[self.ordemProp]) : formataStringAcentuada(a[self.ordemProp]);
          const valueB = !b[self.ordemProp] ? null : self.numericoProp ? parseFloat(b[self.ordemProp]) : formataStringAcentuada(b[self.ordemProp]);
          if(valueA < valueB || valueB === null) return self.ordemDir === 'ASC' ? -1 : 1;
          else if(valueA > valueB || valueA === null) return self.ordemDir === 'ASC' ? 1 : -1;
          else return 0;
        }) :
        self.dados;
    },
    dadosPaginados(){
      const self = this;
      return self.dadosOrdenados.slice(
        (self.paginaAtual - 1) * self.porPagina,
        self.paginaAtual * self.porPagina
      );
    },
    /* Tradução de termos */
    camposTratados() {
      return this.campos.map((e) => {
        return {
          key: e.campo,
          label: e.nome,
          sortable: e.ordenavel,
          variant: e.variante,
          formatter: e.formato,
        };
      });
    },
    paginas() {
      return Math.ceil(this.dados.length / this.porPagina)
    },
    alinhamentos: function() {
      return this.campos.map(function(campo) {
          return campo.alinhamento
      });
    },
  },

  created(){
    const self = this;
    if(!self.id){
      self.id = UspIdUnico.gerar();
    }
  },

  methods: {
    handleClick(dado, idx) {
      if(!this.clicavel) return
      if (this.selecionada === idx) {
        this.selecionada = false;
      }
      else {
        this.selecionada = idx;
      }
      this.$emit('cliqueSimples', {dado, idx});
    },
    handleDblClick(dado, idx) {
    if(!this.clicavel) return
      this.$emit('cliqueDuplo', {dado, idx});
    },
    obterIconeOrdenavel(campo){
      if(campo.campo === this.ordemProp){
        return this.ordemDir === 'ASC' ? 'fas fa-sort-up' : 'fas fa-sort-down';
      } 
      return 'fas fa-sort opaco'
    },
    ordenar({ campo, ordenavel, numerico }){
      const self = this;
      console.log(arguments);
      if(ordenavel) {
        console.log(`Trocando campo ${campo} de ordem`)
        self.ordemProp = campo;
        self.ordemDir = self.ordemDir === 'ASC' ? 'DESC' : 'ASC';
        self.numericoProp = numerico ? true : false;
      }
    },
    tratarCondicao(condicao, dados) {
      return typeof condicao === 'function' ? condicao(dados) : false
    },

    selecionar(acao, dados) {
      this.$emit('selecionar', { acao, dados });
    },

    paginar(n) {
      this.paginaAtual = n;
    },

    formataStringAcentuada
  },
};
</script>

<style lang="scss">
@import "./src/componentes/estilos/temas.scss";

table {
  thead {
    th {
      text-wrap: nowrap;
      div.conteudo {
        align-items: flex-end;
        display: flex;

        &.ordenavel {
          cursor: pointer;
        }

        &.esquerda {
          justify-content: start;
        }
        &.centro {
          justify-content: center;
        }
        &.direita {
          justify-content: end;
        }

        em.fas {
          align-self: center;
          margin-left: .3rem;

          &.opaco {
            opacity: .3;
          }

        }
      }
    }
  }
}

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .table {
      td {
        vertical-align: middle !important;
        &.esquerda {
          text-align: start;
        }
        &.centro {
          text-align: center;
        }
        &.direita {
          text-align: end;
        }
      }

      color: map-get($tema, "usp-elemento-cor-do-texto");
      tr:hover {
        color: map-get($tema, "usp-elemento-cor-do-texto") !important;
      }

      tr {
        td {
          vertical-align: middle !important;
        }

        &.acionavel {
          cursor: pointer;
          &:hover:not(.selecionada) {
            background-color: $usp-cores-cinza-escuro;
          }
        }

        &.selecionada {
          background-color: #d3f5fb;
        }
      }
    }
  }
}
</style>
