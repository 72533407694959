exports.formataData = (data) => {
    return data ? data.substring(8, 10)+"/"+data.substring(5, 7)+"/"+data.substring(0, 4) : null;
}

exports.desformatarData = (data_str) => {
    if(!data_str) return null;
    const hoje = new Date(),
          partes = data_str.split(' '),
          calendario = partes[0].split('/'),
          horario = partes[1].split(':');

    const ano = calendario.length > 2 ? parseInt(calendario[2]) : hoje.getFullYear(),
          mes = calendario.length > 1 ? parseInt(calendario[1]) - 1 : hoje.getMonth(),
          dia = calendario.length > 0 ? parseInt(calendario[0]) : hoje.getDate(),
          horas = horario.length > 0 ? parseInt(horario[0]) : 0,
          minutos = horario.length > 1 ? parseInt(horario[1]) : 0,
          segundos = horario.length > 2 ? parseInt(horario[2]) : 0,
          milisegundos = horario.length > 3 ? parseInt(horario[3]) : 0;

    return new Date(ano, mes, dia, horas, minutos, segundos, milisegundos);
}

exports.stringParaDate = (data) => {
  const partes = data.split('/');
  return new Date(partes[2], partes[1] - 1, partes[0])
}

exports.formataDecimal = (numero) => {
  return numero.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

exports.formataStringAcentuada = (string) => {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
}

exports.formataProcesso = (numero) => {
    if (numero && numero.length == 11) {
        return numero.substring(0, 2) + "." + numero.substring(2, 3) + "." + numero.substring(3, 10)/* + "." + numero.substring(8, 10)*/ + "." + numero.substring(10);
    } else {
        return "";
    }
}

exports.desformatarProcesso = (numero) => {
    if(numero?.length < 6) return '';
    else if(numero.indexOf('.') === -1) return numero.length === 13 ? numero.slice(2) : numero;
    const partes = numero.split('.')
    const ano = partes[0].length > 2 ? partes[0].slice(-2) : partes[0]
    const tipo = partes[1]
    const num  = partes[2].padStart(tipo == 9 ? 7 : 5, '0')
    const unidade = tipo == 9 ? '' : partes[3].padStart(2, '0')
    const digito = partes[partes.length - 1]
    return `${ano}${tipo}${num}${unidade}${digito}`
}

exports.formatarTamanhoArquivo = (tamanho_bytes) => {
    const unidades = ['B', 'KB', 'MB']
    let tamanho = tamanho_bytes
    let count = 0
    while((tamanho / 1024) > 1) {
        tamanho = (tamanho / 1024).toFixed(2)
        count++
    }
    return `${tamanho} ${unidades[count]}`
}